<template>
  <section class="official--partner-overview-wrapper">
    <h1 class="subtitle--text my-3">{{ $t('officialPartner.areaListing') }} {{ name }}</h1>
    <div class="d-flex align-center">
      <div class="btn btn-primary--outline mr-2" v-if="listingType">
        {{ $t('general.' + listingType) }}
      </div>
      <div class="btn btn-primary--outline" v-if="type">
        {{ type }}
      </div>
    </div>
    <div class="address mt-2">
      <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
      <div>{{ address }}</div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    address: {
      type: String,
    },
    type: {
      type: String,
    },
    listingType: {
      type: String,
    },
  },
};
</script>
